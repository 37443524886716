import React,{Component} from "react";
import {isConnected, walletAddress, connect} from '../assets/scripts/wallet.js';
import {maskWalletAddress} from '../assets/scripts/globals.js';
import * as contract from '../assets/scripts/contract.js';
import {ethers} from "ethers";
import {log} from "../assets/scripts/logger.js";

export default class Ranking extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isWalletConnected: false,
      leaderboardPollInterval: 10,
      leaderboardSampleSize: 200,
      leaderboard: []
    }
  }

  connect = (e) => {
    e.preventDefault();
    if(!isConnected()) {
      connect();
    }
  };

  componentDidMount() {

    this.setState({isWalletConnected: isConnected()});

    document.addEventListener('onAccountUpdate', () => {

      const connected = isConnected();

      this.setState({isWalletConnected: connected});

      if(connected) {
        this.pollLeaderboard();
        this.setupPoller();
      }

    });

    this.setupPoller();

  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId); 
  }

  setupPoller = () => {

    if(this.state.intervalId) {
      clearInterval(this.state.intervalId); 
    }

    const intervalId = setInterval(() => this.pollLeaderboard(), this.state.leaderboardPollInterval * 1000);
    this.setState({ intervalId })

  };

  pollLeaderboard = async () => {

    if(!isConnected()) {
      return;
    }

    const leaderboard = await contract.queryLeaderboard(this.state.leaderboardSampleSize);

    if(leaderboard && leaderboard.length > 0) {
      const record = leaderboard[0];
      log("[RANKING]: Leaderboard[0] " + JSON.stringify(record));
      log("[RANKING]: Leaderboard[0][team] " + record[0]);
      log("[RANKING]: Leaderboard[0][graveyardLevel] " + record[1]);
      log("[RANKING]: Leaderboard[0][allTimeSpiritBalance] " + record[2]);
      log("[RANKING]: Leaderboard[0][lastHarvestTimestamp] " + record[3]);
      log("[RANKING]: Leaderboard[0][account] " + record[4]);
    }
    
    this.setState({leaderboard: leaderboard});

  };

  render() {
    var rows = [
      <tr className="leaderboard-list-header" key="header">
          <td>RANK</td>
          <td>PLAYER</td>
          <td>LEVEL</td>
          <td>POINTS</td>
      </tr>,
      <tr className="leaderboard-list-spacer" key="spacer"><td></td><td></td><td></td><td></td></tr>
    ];
    if(this.state.leaderboard && this.state.leaderboard.length > 0) {
      for (var i = 0; i < this.state.leaderboard.length; i++) {
        rows.push(
          <tr className={"leaderboard-list-item " + (this.state.leaderboard[i][0] === 1 ? "purple" : "green") + (this.state.leaderboard[i][4].toLowerCase() === walletAddress(false) ? " mine" : "")} key={i + 1}>
            <td>{(i + 1).toString().padStart(3)}</td>
            <td>{maskWalletAddress(this.state.leaderboard[i][4])}</td>
            <td>{this.state.leaderboard[i][1]}</td>
            <td>{parseFloat(ethers.utils.formatUnits(this.state.leaderboard[i][2], 18)).toFixed(2).padStart(10)}</td>
          </tr>
        );
      }
    } else {
      rows.push(
        <tr className="leaderboard-list-item" key="placeholder">
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
      );
    }
    return (
      <section className="leaderboard-banner" id="banner">
        <div className="leaderboard-overlay"></div>
        <div className="leaderboard-container">
          <div className="row">
            <div className="col-xl-12">
              <div className="col-xl-3 col-lg-6"></div>
              <div className="banner-one__content">
                <h1 className="banner-one__title">
                  LEADERBOARD
                </h1>
                {this.state.isWalletConnected
                  ? 
                  <div className="leaderboard-list-container">
                    <table className="leaderboard-list">
                      <tbody>
                        {rows}
                      </tbody>
                    </table>
                  </div>
                  :
                  <a href="#" className="banner-one__btn thm-btn mint-btn" onClick={this.connect}>
                    <span>CONNECT</span>
                  </a>
                }
              </div>
              <div className="col-xl-3 col-lg-6"></div>
            </div>
          </div>
        </div>
      </section>
    )
  }

}