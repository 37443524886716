import React from "react";
import Layout from "../components/Layout";
import Nav from "../components/Nav";
import Ranking from "../components/Ranking";

const Leaderboard = () => (
  <Layout pageTitle="AVAXGHOST NFT Game">
    <Nav />
    <Ranking />
  </Layout>
);

export default Leaderboard;